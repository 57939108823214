<template>
	<div>p</div>
</template>

<script>
export default {
    name: 'AuthError'
};
</script>

<style scoped lang="less">

</style>
